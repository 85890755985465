<template>
    <div class="side-bar" :class="{ hidden: !expanded }">
        <div class="side-bar-body">
            <div class="side-bar-logo-td" @click="openHome">
                <img class="logo-text" src="/svg/logo.svg">
            </div>

            <router-link class="side-bar-option" :title="$t('Home')" :to="{ name: 'home' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/dashboard.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'home' }">{{ $t("Dashboard") }}</div>
            </router-link>

            <router-link class="side-bar-option" :title="$t('Data Providers')" :to="{ name: 'data-provider' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/dataprovider.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'data-provider' }">{{ $t("Data Providers") }}</div>
            </router-link>
            
            <router-link class="side-bar-option" :title="$t('Energy')" :to="{ name: 'energy' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/energy.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'energy' }" >{{ $t("Energy Market") }}</div>
            </router-link>

            <router-link v-if="walletKind === 'unverified'" class="side-bar-option" :title="$t('Wallet identity verification')"
                :to="{ name: 'wallet-verification' }">
                <div class="side-bar-option-icon"><i class="fas fa-user-check"></i></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'wallet-verification' }" >{{ $t("Identity verification") }}</div>
            </router-link>

            <router-link v-if="walletKind === 'prosumer' || walletKind === 'producer'" class="side-bar-option" :title="$t('Sale Offers')" :to="{ name: 'sales-offers' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/sales-offers.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'sales-offers' }" >{{ $t("My Sales Offers") }}</div>
            </router-link>

            <router-link v-if="walletKind === 'prosumer' || walletKind === 'consumer'" class="side-bar-option" :title="$t('Demand Supplies')" :to="{ name: 'demand-supplies' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/demands.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'demand-supplies' }" >{{ $t("My Demand Supplies") }}</div>
            </router-link>

            <!--<router-link class="side-bar-option" :title="$t('Energy')" :to="{ name: 'energy-demo' }">
                <div class="side-bar-option-icon"><i class="fas fa-bolt"></i></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'energy-demo' }" >{{ $t("Energy") }} - Demo</div>
            </router-link>-->

            <router-link class="side-bar-option" :title="$t('Auction')" :to="{ name: 'auctions' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/auctions.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'auctions' }" >{{ $t("Auctions") }}</div>
            </router-link>

            <div class="side-bar-separator"></div>

            <router-link class="side-bar-option" :title="$t('Policies')" :to="{ name: 'policies' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/policies.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'policies' }" >{{ $t("Policies") }}</div>
            </router-link>

            <router-link class="side-bar-option" :title="$t('Contracts')" :to="{ name: 'contracts' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/contracts.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'contracts' }" >{{ $t("Contracts") }}</div>
            </router-link>

            <router-link class="side-bar-option" :title="$t('Transfers')" :to="{ name: 'transfers' }">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/transfers.svg"/></div>
                <div class="side-bar-option-text" :class="{ selected: page === 'transfers' }" >{{ $t("Transfers") }}</div>
            </router-link>

            <!--<div class="side-bar-separator"></div>
            
            <router-link
                class="side-bar-option"
                :class="{ selected: page === 'terms' }"
                :title="$t('Terms of use')"
                :to="{ name: 'terms' }"
            >
                <div class="side-bar-option-icon"><i class="fas fa-scale-balanced"></i></div>
                <div class="side-bar-option-text">{{ $t("Terms of use") }}</div>
            </router-link>

            <router-link
                class="side-bar-option"
                :class="{ selected: page === 'about' }"
                :title="$t('Cookies policy')"
                :to="{ name: 'cookies' }"
            >
                <div class="side-bar-option-icon"><i class="fas fa-cookie-bite"></i></div>
                <div class="side-bar-option-text">{{ $t("Cookies policy") }}</div>
            </router-link>

            <router-link
                class="side-bar-option"
                :class="{ selected: page === 'privacy' }"
                :title="$t('Privacy policy')"
                :to="{ name: 'privacy' }"
            >
                <div class="side-bar-option-icon"><i class="fas fa-shield"></i></div>
                <div class="side-bar-option-text">{{ $t("Privacy policy") }}</div>
            </router-link>
            -->

            <div class="side-bar-separator"></div>

            <a class="side-bar-option close" :title="$t('Log out')" @click="logout">
                <div class="side-bar-option-icon"><img class="btn-image" src="@/assets/sidebar/logout.svg"/></div>
                <div class="side-bar-option-text">{{ $t("Log out") }}</div>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { AuthController } from "@/control/auth";

export default defineComponent({
    components: {},
    name: "MenuSideBar",
    emits: ["update:expanded", "openModal"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
        };
    },
    data: function () {
        return {
            page: "",
            walletKind: AuthController.Wallet ? AuthController.Wallet.kind : "",
        };
    },
    methods: {
        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : "";
        },

        onAuthChanged: function () {
            this.walletKind = AuthController.Wallet ? AuthController.Wallet.kind : "";
        },

        logout: function () {
            this.$emit("openModal", "logout");
        },

        openHome: function() {
            this.$router.push({ name: "home" });
        },
    },
    mounted: function () {
        this.updatePage();

        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));

        this.onAuthChanged();
    },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
});
</script>

<style></style>
